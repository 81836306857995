<template>
  <div
    v-if="isLoading"
    class="q-notifications__list q-notifications__list--top fixed column no-wrap items-center"
  >
    <div
      role="alert"
      class="q-notification row items-stretch q-notification--standard text-white q-pa-sm"
    >
      <div class="q-notification__wrapper col relative-position border-radius-inherit row items-center">
        <div class="q-notification__content row items-center col">
          <svg
            focusable="false"
            width="1em"
            height="1em"
            viewBox="25 25 50 50"
            class="q-spinner q-spinner-mat q-notification__spinner"
          >
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="currentColor"
              stroke-width="5"
              stroke-miterlimit="10"
              class="path"
            />
          </svg>

          <div class="q-notification__message col">
            {{ $t('Please wait...') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Loader',
  computed: {
    ...mapGetters([
      'isLoading'
    ])
  }
}
</script>
